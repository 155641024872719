.hero-main {
  font-family: 'DM Sans Regular';
}
.main {
  display: flex;
  padding: 1rem 1rem;
  margin: 0px auto;
  margin-top: 4rem;
}

.greet-main {

}

.greeting-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.greeting-container {
  display: flex;
  font-family: "Google Sans Medium";
  flex-direction: column;
  align-items: center;
}

.greeting-text-title {
  font-size: 6rem;
  line-height: 1;
  text-align: center;
}

.greeting-text-subtitle {
  margin-top: 2rem;
  font-size: 3rem;
  line-height: 1;
}

.greet-main {
  width: 90%;
  padding: 1rem 1rem;
  margin: 0px auto;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

/* Media Query */
@media (max-width: 1380px) {
}

@media (max-width: 768px) {
}

@media (max-width: 600px) {

  .greeting-text-title {
    font-size: 3rem;
  }
  
}
